<template>
  <component :is="invoiceData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="invoiceData === undefined">
      <h4 class="alert-heading">Erro ao carregar dados</h4>
      <div class="alert-body">
        Faturamento não encontrado
        <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
          Lista de notas fiscais
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <template v-if="invoiceData">
      <div>
        <b-card>
          <h2 class="mb-2">
            {{ $t("message.invoices.editInvoice") }}
          </h2>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form @submit.prevent="handleSubmit(updateInvoice())">
              <b-row>
                <b-col md="8" class="mb-1">
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                  >
                    <b-form-group label-for="numberInvoices">
                      <template v-slot:label>
                        {{ $t("message.invoices.number") }}
                      </template>
                      <b-form-input
                        v-model="invoiceData.number"
                        label="number"
                        label-for="nameInvoices"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="2">
                  <b-form-group label-for="invoiceDate">
                    <template v-slot:label>
                      {{ $t("message.invoices.revenues") }}
                    </template>
                    <flat-pickr
                      id="invoice-date"
                      v-model="invoiceData.invoiceDate"
                      class="mt-0 form-control"
                      :config="flatConfig"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label-for="invoiceExpiryDate">
                    <template v-slot:label>
                      {{ $t("message.invoices.Duedate") }}
                    </template>
                    <flat-pickr
                      id="invoice-expiry-date"
                      v-model="invoiceData.invoiceExpiryDate"
                      class="mt-0 form-control"
                      :config="flatConfig"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label-for="customerInvoices">
                    <template v-slot:label>
                      {{ $t("message.invoices.costumer") }}
                    </template>
                    <v-select
                      v-model="invoiceData.customerId"
                      :options="customerOptions"
                      :clearable="false"
                      :reduce="(val) => val.value"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label-for="contract">
                    <template v-slot:label>
                      {{ $t("message.invoices.Contract") }}
                    </template>
                    <v-select
                      v-model="invoiceData.contractId"
                      :options="contractOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="4">
                  <b-form-group label-for="value">
                    <template v-slot:label>
                      {{ $t("message.invoices.value") }}
                    </template>
                    <b-form-input
                      label="value"
                      v-model="invoiceData.value"
                      v-money="getCurrencyFormat()"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label-for="contractInstallment">
                    <template v-slot:label>
                      {{ $t("message.invoices.installments") }}
                    </template>
                    <b-form-input v-model="installments" />
                    <small style="color: lightgrey">
                      {{ $t("message.invoices.separateWith") }}
                    </small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label-for="statusInvoices">
                    <template v-slot:label>
                      {{ $t("message.invoices.states") }}
                    </template>
                    <v-select
                      v-model="invoiceData.status"
                      :options="status"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12" v-if="invoiceData.taxes">
                  <br />
                  <h3>{{ $t("tax") }}</h3>
                  <br />
                  <div
                    class="form-group"
                    v-for="(input, k) in invoiceData.taxes"
                    :key="k"
                  >
                    <b-row>
                      <b-col md="3">
                        {{ $t("taxName") }}
                        <b-form-input v-model="input.name" />
                      </b-col>
                      <b-col md="2">
                        {{ $t("taxValue") }}
                        <b-form-input
                          v-model="input.value"
                          v-money="getCurrencyFormat()"
                        />
                      </b-col>

                      <b-col md="2" class="mt-2">
                        <b-button
                          v-show="k == invoiceData.taxes.length - 1"
                          variant="primary"
                          class="btn-icon"
                          @click="addInstallment(k)"
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                      </b-col>
                      <b-col>
                        <b-button
                          md="2"
                          v-show="k || (!k && invoiceData.taxes.length > 1)"
                          variant="danger"
                          class="btn-icon mt-2"
                          @click="removeInstallment(k)"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </template>
    <div class="text-right">
      <b-button
        variant="primary"
        @click="updateInvoice()"
        :to="{ name: 'apps-invoice-list' }"
      >
        <template>
          {{ $t("message.buttons.save") }}
        </template>
      </b-button>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import dateInputEdit from "@/@core/utils/dateInputEdit";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      invoiceData: [],

      customerOptions: [],
      contractOptions: [],

      status: [
        { label: "Aberto", value: "Aberto" },
        { label: "Fechado", value: "Fechado" },
      ],

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      installments: "",
      userData: store.state.user.userData,

      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInputEdit(newVal);
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInputEdit(this.$i18n.locale);
    }
  },

  async created() {
    axios({
      method: "get",
      url: `${URL_API}invoice/${router.currentRoute.params.id}`,
      headers: getHeader(this.userData),
    })
      .then((response) => {
        this.invoiceData = response.data;
        this.installments = response.data.installment.join(",");
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.invoiceData = undefined;
        }
      });

    var customerList = [];
    var customerId = [];
    await axios
      .get(`${URL_API}customer/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          customerList.push(element);
          customerId.push(id);
          this.customerOptions.push({
            label: customerList[i],
            value: customerId[i],
          });
        }
      });

    var contractList = [];
    var contractId = [];
    await axios
      .get(`${URL_API}contract/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          contractList.push(element);
          contractId.push(id);
          this.contractOptions.push({
            label: contractList[i],
            value: contractId[i],
          });
        }
      });
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    addInstallment() {
      this.invoiceData.taxes.push({ name: "", value: "" });
    },

    removeInstallment(index) {
      this.invoiceData.taxes.splice(index, 1);
    },

    updateInvoice() {
      if (this.$i18n.locale === "en") {
        this.invoiceData.value = Number(
          this.invoiceData.value.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      } else {
        this.invoiceData.value = this.switchDotsAndCommas(
          this.invoiceData.value
        );
        this.invoiceData.value = Number(
          this.invoiceData.value.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      }

      if (this.invoiceData.taxes) {
        for (var i = 0; i < this.invoiceData.taxes.length; i++) {
          if (this.$i18n.locale === "en") {
            this.invoiceData.taxes[i].value = Number(
              this.invoiceData.taxes[i].value.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);
          } else {
            this.invoiceData.taxes[i].value = this.switchDotsAndCommas(
              this.invoiceData.taxes[i].value
            );
            this.invoiceData.taxes[i].value = Number(
              this.invoiceData.taxes[i].value.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);
          }
        }
      }

      this.invoiceData.installment = [];
      this.invoiceData.installment = this.installments.split(",");

      axios({
        method: "put",
        url: `${URL_API}invoice/${this.invoiceData.id}`,
        headers: getHeader(this.userData),
        data: this.invoiceData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updatedB"),
          },
        });
      });
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },
  },
};
</script>
